.iconrow{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;


  @include desktop(){
    flex-direction: row;
    height: 135px;
  }

  //.js-icon2{
  //  margin-left: 20px;
  //}

  img {
    height: 60px;
    width: 60px;
    margin: 20px 0;
    display: block;


    @include desktop() {
      height: 80px;
      width: 80px;
      margin: 30px 0;
    }

  }

  &.iconrow--small{
    display: flex;
    flex-direction: column;

    @include desktop() {
      display: flex;
      flex-direction: row;
    }

    img {
      height: 35px;
      width: 35px;
      margin: 20px 0;
      display: block;
      border-radius: 50%;
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);

      @include desktop() {
        height: 50px;
        width: 50px;
        margin: 0;
      }

    }
  }

  .iconrow-group{
    margin: 10px 0 20px 0;

    .iconrow-title{
      text-transform: uppercase;
      color: $pagination-darkblue;
      font-family: $font-family-bold;
      font-size: 12px;
      text-align: center;
    }

    .iconrow-body{
      display: flex;
      flex-direction: row;

      .iconrow-body-area{
        width: 100vw;

        @include tabletUp() {
          width: 150px;
        }

        img{
          position: relative;
          margin: 5px auto;
          border-radius: 50%;
          box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);
        }

        .iconrow-bodytext{
          color: $pagination-darkblue;
          font-family: $font-family;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
        }
      }
    }
  }
}

.top-btns{
  z-index: 200;
  display: flex;
  position: absolute;
  top: 45px;
  right: 10px;
  height: 150px;
  flex-direction: column;
  justify-content: center;

  @include tablet(){
    right: 30px;
    top: -20px;
    position: fixed;
  }

  @include desktop(){
    right: 55px;
    top: 0;
    position: fixed;
  }

  .btn-area{
    .button{
      background: #fff;
      color: $pagination-darkblue;
      padding: 15px 50px;
    }
  }
}

.js-icon-append{
  //width: 0;
  opacity: 0;
  margin-left: 0;
}

.stuck {
  position:fixed;
  top:0;
  left: 0;
  right: 0;
  z-index: 100;
  background-size: 100% auto;
  background-position: top center;
  background-image: url('/img/background.png');
}

.logoArea{
  position: relative;

  .logoArea-container{
    position: absolute;
    top: -40px;
    bottom: 0;
    left: 20px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include desktop(){
      top: 0;
      left: 50px;
    }

    .logo{
      height: 30px;
      width: auto;
      float: left;

      @include tabletUp(){
        height: 75px;
      }
    }
  }



}

