// Buttons
//––––––––––––––––––––––––––––––––––––––––––––––––––

.button {
  padding: 10px 25px;
  color: $white;
  text-align: center;
  font-size: 16px;
  font-family: $font-family-bold;
  line-height: 20px;
  text-decoration: none;
  white-space: nowrap;
  background-color: $orange;
  cursor: pointer;
  box-sizing: border-box;
  transition: all 0.5s ease 0s;
  width: 100%;
  border-radius: 30px;

  @include tablet(){
    font-size: 18px;
    line-height: 23px;
    padding: 15px 40px;
  }
  @include desktop(){
    font-size: 18px;
    line-height: 23px;
    padding: 20px 50px;
  }

  &.link{
    color: $white;
    font-size: 14px;
    font-weight: $font-weight-semibold;
    line-height: 16px;
  }
  &:visited{
    color: $white;
    font-weight: $font-weight-semibold;
  }
  &:hover{
    background: lighten($orange, 10%);
    outline: 0;
    color: $white;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.25);
  }
  &:focus{
    background: lighten($orange, 15%);
    outline: 0;
    color: $white;
  }
  &:active{
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);
  }

}

.link {
  transition: all 0.3s ease 0s;
  color: $dark_gray;
  text-decoration: underline !important;
  text-align: center;
  font-size: 14px;
  font-family: $font-family-light;
  line-height: 14px;
  white-space: nowrap;
  cursor: pointer;

  @include tabletUp() {
    font-size: 18px;
    line-height: 18px;
  }

  &:hover{
    color: $black;
  }
}

.btn-startover{
  @include mobile(){
    transition: all 0.3s ease 0s;
    color: $dark_gray;
    text-decoration: underline !important;
    text-align: center;
    font-size: 14px;
    font-family: $font-family-light;
    line-height: 14px;
    white-space: nowrap;
    cursor: pointer;
    padding: 0 !important;
    background-color: transparent !important;

    &:hover{
      background: transparent !important;
      box-shadow: none !important;
    }

  }

}
