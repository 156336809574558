// Core styles for the entire website
//@import "normalize";
@import "variables";


$font-size: 16px;

* {
  box-sizing: border-box;
  font-family: $font-family;
  font-weight: $font-weight-light;
}

body {
  font-size: 16px;
  line-height: 1.7em;
  font-family: $font-family;
  color: $gray;
  //background-size: cover;
  background-size: 100% auto;
  background-position: top center;
  background-image: url('/img/background.png');
  float: left;
}

button{
  outline: none;
}

.site-content{
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
}

section{
  position: relative;
  height: 100%;
  z-index: 0;
}

a:link {
  text-decoration: none;
  font-weight: $font-weight-light;
}
a:visited {
  font-weight: $font-weight-light;
}
a:hover,
a:visited:hover {
  text-decoration: none;
  font-weight: $font-weight-light;
}
a:active,
a:visited:active {
  font-weight: $font-weight-light;
}


.h3 {
  margin-top: 2.5em;
  margin-bottom: .3em;
  padding: 0;
  font-size: 24px;
  font-weight: 100;
  font-family: $font-family;
  line-height: 1.5em;
}
