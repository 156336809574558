.recommendation-block{
  margin-top: 60px;

  h3{
    font-family: $font-family-trailhead;
    font-size: 20px;
    line-height: 26px;

    @include desktop(){
      font-size: 24px;
      line-height: 32px;
    }
  }

  .recommendation-block-area{
    background: #ffffff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-shadow: 0 3px 6px rgba(0,0,0,0.05), 0 10px 30px rgba(0,0,0,0.10);

    .recommendation{
      display: flex;
      flex-direction: row;
      flex-basis: 100%;
      width: 100%;
      border-bottom: 1px solid $recommend-line;
      padding: 10px 15px;
      font-size: 13px;
      line-height: 18px;

      &:last-of-type{
        border-bottom: none;
      }

      @include desktop(){
        padding: 15px 30px;
      }

      &.recommendation--title{
        text-transform: uppercase;
        font-size: 12px;
        line-height: 15px;

        div{
          padding: 5px 0 ;
        }
      }

      .title {
        flex-basis: calc(100% - 100px);
        width: calc(100% - 100px);
        text-align: left;
        color: $pagination-darkblue;

        @include tabletUp(){
          flex-basis: calc(100% - 180px);
          width: calc(100% - 180px);
        }
      }
      .icon{
        flex-basis: 100px;
        width: 100px;
        text-align: right;
        color: $pagination-darkblue;

        @include tabletUp(){
          flex-basis: 180px;
          width: 180px;
        }

        .iconhelp{
          font-size: 11px;
          line-height: 14px;
          color: $recommend-line;
          font-family: $font-family-light-italic;
        }

        .icon-x {
          color: $recommend-line;
          font-size: 18px;

        }

        .icon-check {
          color: $recommend-check;
          font-size: 20px;

        }
      }


      //&:hover{
      //  border-color: $blue;
      //}

    }
  }

}


.recommendation-page{
  p {
    max-width: 100%;
  }
  .iconrow{

    @include tabletUp(){
      position:fixed;
      top:0;
      left: 0;
      right: 0;
      z-index: 500;
      background-size: 100% auto;
      background-position: top center;
      background-image: url('/img/background.png');
    }

  }
  .top-btns{
    z-index: 520;
    top: 65px;

    @include desktop(){
      top: 0;
    }
  }
  .logoArea{
    z-index: 520 !important;
    position: fixed;
    top:0;
    left: 0;
    right: 0;

    .logoArea-container{
      z-index: 520 !important;
      display: none;

      @include tabletUp(){
        display: block;
      }
      @include desktop(){
        top: 35px;
      }
    }

  }

  .block{
    margin-top: 0;
    padding: 40px 20px 60px 20px;

    @include tabletUp() {
      margin-top: 140px;
      padding: 60px 20px;
    }
  }

  .recommendation-page-intro{
    margin: 30px 0;

    @include desktop(){
      margin: 80px 0;
    }

    h2{
      font-family: $font-family-trailhead;
      font-size: 22px;
      line-height: 28px;
      margin: 30px 0;

      @include desktop(){
        margin: 80px 0;
        font-size: 32px;
        line-height: 32px;
      }
    }
    p{
      max-width: 100%;
      font-size: 16px;
      line-height: 20px;
      margin: 5px 0 0 0;

      @include desktop(){
        font-size: 18px;
        line-height: 23px;
      }
    }
  }


  .iconrow {
    height: 120px;
    @include desktop(){
      height: 135px;
    }
  }


  .iconrow-group {
    width: 100vw;
    @include desktop(){
      width: 350px;
    }
  }

  .js-icon2{
    margin-left: 0;
  }

  .iconrow-body-area{
    img{
      display: none;

      @include tabletUp(){
        display: block;
      }
    }
  }

    //.iconrow-body{
  //  display: flex;
  //  flex-direction: column !important;
  //
  //  @include desktop(){
  //    display: flex;
  //    flex-direction: row;
  //  }
  //
  //}
  .iconrow-group1{
      position: relative;
      margin: 5px auto 0 auto;


      @include desktop(){
        margin: 10px 0 20px 0;
        padding-right: 30px;
      }
    }

  .iconrow-group2{
      position: relative;

      @include desktop(){
        padding-left: 30px;
      }

      &:before{
        position: absolute;
        content: '';
        border-left: 2px solid #54698d;
        left: 0;
        top: 35px;
        bottom: 35px;
      }
    }

}
