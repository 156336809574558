.pagination{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin: 30px auto;

  .box-row{
    width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: row;

    .box-container{
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 0 auto;


      .box{
        width: 40px;
        height: 40px;
        font-size: 18px;
        background: #fff;
        border: 1px solid $pagination-gray;
        font-family: $font-family-light;
        color: $pagination-gray;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 10px;
        text-align: center;
        border-radius: 4px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);


        &.box-current{
          border: 3px solid $blue;
          font-family: $font-family-bold;
          color: $blue;

        }

        &.box-previous {
          background: $pagination-darkblue;
          border-color: transparent !important;
          content: '';
          display: block;
          position: relative;



          &:after {
            transform: scaleX(-1) rotate(180deg + -45deg);
            transform-origin: left top;
            border-right: 3px solid #fff;
            border-top: 3px solid #fff;
            content: '';
            display: block;
            height: 20px;
            left: 7px;
            position: absolute;
            top: 20px;
            width: 10px;

            @include animation(check 1s);
          }

          &:hover {
            &:after {
              border-color: $light_gray;
            }
          }
          img{
            margin: 7px;
          }
        }
      }
    }
  }

  .box-text{
    margin-top: 20px;
    width: 100%;
    flex-basis: 100%;
    color: $pagination-darkblue;
    text-transform: uppercase;
    text-align: center;
    font-family: $font-family-bold;
    font-size: 13px;
    line-height: 17px;
  }



}
