
h1{
  font-size: 22px;
  line-height: 30px;
  color: $dark_gray;
  margin: 15px 0 0 0;
  font-family: $font-family-trailhead;

  @include desktop() {
    font-size: 32px;
    line-height: 42px;
    margin: 0;
  }
}

.homepage{
  h1{
    font-size: 22px;
    line-height: 30px;
    color: $dark_gray;
    margin: 0;
    font-family: $font-family-trailhead;

    @include desktop() {
      font-size: 38px;
      line-height: 48px;

    }
  }

}


h5{
  font-size: 16px;
  line-height: 20px;
  color: $dark_gray;
  margin: 10px 0;
  font-family: $font-family-light;

  @include desktop() {
    font-size: 24px;
    line-height: 26px;
    margin: 20px 0;
  }
}

p{
  font-size: 16px;
  line-height: 20px;
  color: $dark_gray;
  margin: 20px auto;
  font-family: $font-family-light;
  max-width: 700px;
  width: 100%;

  @include tabletUp(){
    font-size: 18px;
    line-height: 24px;
  }
}

.smalltext{
  font-size: 14px;
  line-height: 18px;
  color: $dark_gray;
  font-family: $font-family-light;
}
