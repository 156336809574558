@charset "UTF-8";

@font-face {
  font-family: "SalesforceSans-Bold";
  src:url("/fonts/SalesforceSans-Bold.eot");
  src:url("/fonts/SalesforceSans-Bold.eot?#iefix") format("embedded-opentype"),
  url("/fonts/SalesforceSans-Bold.woff") format("woff"),
  url("/fonts/SalesforceSans-Bold.ttf") format("truetype"),
  url("/fonts/SalesforceSans-Bold.svg#SalesforceSans-Bold") format("svg");
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: "SalesforceSans-BoldItalic";
  src:url("/fonts/SalesforceSans-BoldItalic.eot");
  src:url("/fonts/SalesforceSans-BoldItalic.eot?#iefix") format("embedded-opentype"),
  url("/fonts/SalesforceSans-BoldItalic.woff") format("woff"),
  url("/fonts/SalesforceSans-BoldItalic.ttf") format("truetype"),
  url("/fonts/SalesforceSans-BoldItalic.svg#SalesforceSans-BoldItalic") format("svg");
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: "SalesforceSans-Italic";
  src:url("/fonts/SalesforceSans-Italic.eot");
  src:url("/fonts/SalesforceSans-Italic.eot?#iefix") format("embedded-opentype"),
  url("/fonts/SalesforceSans-Italic.woff") format("woff"),
  url("/fonts/SalesforceSans-Italic.ttf") format("truetype"),
  url("/fonts/SalesforceSans-Italic.svg#SalesforceSans-Italic") format("svg");
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: "SalesforceSans-Light";
  src:url("/fonts/SalesforceSans-Light.eot");
  src:url("/fonts/SalesforceSans-Light.eot?#iefix") format("embedded-opentype"),
  url("/fonts/SalesforceSans-Light.woff") format("woff"),
  url("/fonts/SalesforceSans-Light.ttf") format("truetype"),
  url("/fonts/SalesforceSans-Light.svg#SalesforceSans-Light") format("svg");
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: "SalesforceSans-LightItalic";
  src:url("/fonts/SalesforceSans-LightItalic.eot");
  src:url("/fonts/SalesforceSans-LightItalic.eot?#iefix") format("embedded-opentype"),
  url("/fonts/SalesforceSans-LightItalic.woff") format("woff"),
  url("/fonts/SalesforceSans-LightItalic.ttf") format("truetype"),
  url("/fonts/SalesforceSans-LightItalic.svg#SalesforceSans-LightItalic") format("svg");
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: "SalesforceSans-Regular";
  src:url("/fonts/SalesforceSans-Regular.eot");
  src:url("/fonts/SalesforceSans-Regular.eot?#iefix") format("embedded-opentype"),
  url("/fonts/SalesforceSans-Regular.woff") format("woff"),
  url("/fonts/SalesforceSans-Regular.ttf") format("truetype"),
  url("/fonts/SalesforceSans-Regular.svg#SalesforceSans-Regular") format("svg");
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: "SalesforceSans-Thin";
  src:url("/fonts/SalesforceSans-Thin.eot");
  src:url("/fonts/SalesforceSans-Thin.eot?#iefix") format("embedded-opentype"),
  url("/fonts/SalesforceSans-Thin.woff") format("woff"),
  url("/fonts/SalesforceSans-Thin.ttf") format("truetype"),
  url("/fonts/SalesforceSans-Thin.svg#SalesforceSans-Thin") format("svg");
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: "SalesforceSans-ThinItalic";
  src:url("/fonts/SalesforceSans-ThinItalic.eot");
  src:url("/fonts/SalesforceSans-ThinItalic.eot?#iefix") format("embedded-opentype"),
  url("/fonts/SalesforceSans-ThinItalic.woff") format("woff"),
  url("/fonts/SalesforceSans-ThinItalic.ttf") format("truetype"),
  url("/fonts/SalesforceSans-ThinItalic.svg#SalesforceSans-ThinItalic") format("svg");
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: "Trailhead-Bold";
  src:url("/fonts/trailhead_bold.eot");
  src:url("/fonts/trailhead_bold.eot?#iefix") format("embedded-opentype"),
  url("/fonts/trailhead_bold.woff") format("woff"),
  url("/fonts/trailhead_bold.ttf") format("truetype"),
  url("/fonts/trailhead_bold.svg#trailhead_bold") format("svg");
  font-weight: normal;
  font-style: normal;

}

@charset "UTF-8";

@font-face {
  font-family: "salesforce-inbox";
  src:url("/fonts/salesforce-inbox.eot");
  src:url("/fonts/salesforce-inbox.eot?#iefix") format("embedded-opentype"),
  url("/fonts/salesforce-inbox.woff") format("woff"),
  url("/fonts/salesforce-inbox.ttf") format("truetype"),
  url("/fonts/salesforce-inbox.svg#salesforce-inbox") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "salesforce-inbox" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "salesforce-inbox" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-x:before {
  content: "\61";
}
.icon-check:before {
  content: "\62";
}
