.card-row{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  //margin: 20px auto;
  max-width: 700px;
  width: 100%;

  @include desktop(){
    margin: 80px auto;
  }

  .card-link{
    color: $gray;
    text-align: center;
    font-size: 18px;
    font-family: $font-family-light;
    line-height: 23px;
    cursor: pointer;
    width: calc(100% / 2);
    transition: all 0.75s ease;

    .card-text{
      color: $gray;
      text-align: center;
      font-family: $font-family-light;
      font-size: 14px;
      line-height: 18px;

      @include desktop(){
        font-size: 18px;
        line-height: 23px;
      }
    }

    &.card-active{
      .card{
        border-color: $blue;
      }
      .card-text{
        font-family: $font-family-bold;
      }
    }

    &.card-not-available {

      .card{
        &:before{
          content: 'Not Available';
          position: absolute;
          top: 17px;
          left: 0;
          right: 0;
          background: #54698d;
          font-size: 10px;
          line-height: 12px;
          color: #fff;
          letter-spacing: .6px;
          text-transform: uppercase;
          padding: 8px 5px;
          height: 21px;
          border-radius: 20px;
          z-index: 100;

          @include tabletUp() {
            top: 50px;
            left: 6px;
            right: 6px;
            font-size: 10px;
            padding: 2px 10px;
            height: 21px;
            line-height: 22px;
          }
        }

        &:after{
          content: '';
          background: $light_gray;
          opacity: 0.8;
        }

        img{
          opacity: 0.25;
        }


      }

      &:hover, &:visited, &:focus{
        cursor: not-allowed;
        .card{
          border-color: $light_gray;
          box-shadow: none;

        }
        .card-text{
          font-family: SalesforceSans-Light;
        }
        .smalltext{

        }
      }
    }

    &:visited{
      color: $gray !important;
    }

    &:hover{
      cursor: pointer;


      .card{
        border-color: $blue;
        box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.25);
      }
      .smalltext{
        font-family: $font-family-light;
      }

    }
    &:focus{
      color: $gray !important;
      .smalltext, .card-text{
        font-family: $font-family-bold;
      }
      .card{
          box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);
      }
    }



    .smalltext{
      margin-top: 5px;
      font-size: 12px;
      line-height: 14px;

      @include desktop(){
        font-size: 14px;
        line-height: 18px;

      }
    }

    .card {
      margin: 0 auto 15px auto;
      height: 80px;
      width: 80px;
      background: $white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 50%;
      border: 5px solid $light_gray;
      transition: all 0.5s ease;
      position: relative;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);

      @include tabletUp(){
        height: 130px;
        width: 130px;
        margin: 0 auto 40px auto;
      }

      img{
        height: 33px;
        margin: auto;
        -ms-interpolation-mode: bicubic;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        image-rendering: -webkit-optimize-contrast; // Safari 6, UC Browser 9.9
        image-rendering: -webkit-crisp-edges; // Safari 7+
        image-rendering: -moz-crisp-edges; // Firefox 3.6+
        image-rendering: -o-crisp-edges; // Opera 12
        image-rendering: crisp-edges; // Chrome 41+ and Opera 26+
        shape-rendering: geometricPrecision;
        image-rendering: optimizeSpeed !important;

        @include tabletUp(){
          height: 63px;
        }
      }

    }


  }


}


.email-server{
  .card-link{
    width: calc(100% / 3);
  }
}
