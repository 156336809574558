.homepage{
  .block {
    margin: 0 0 80px 0;
  }

}

.block{
  //margin: 0 0 80px 0;
  width: 100vw;
  height: 100%;
  background: rgba(255,255,255,0.8);
  color: $dark_gray;
  padding: 30px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;


  @include desktop() {
    padding: 60px;
  }


  .block-container{
    max-width: 90vw;
    margin: 0 auto;
    align-self: center;

    @include desktop() {
      max-width: 860px;
      margin: 0 auto;
    }

    .block-section{
      display: flex;
      flex-direction: column;
      margin-top: 60px;

      @include tabletUp(){
        flex-direction: row;
      }

      &.block-section--sync{
        margin-top: 40px;

        .block-section-column{
          .block-section-column--image{
            @include tabletUp(){
              margin: 40px 20px;
            }
          }
        }

      }

      .block-section-column{
        width: 100%;
        flex-basis: 100%;
        display: flex;
        flex-direction: column;

        @include tabletUp(){


          &:first-of-type{
            margin-right: 15px;
            width: 45%;
            flex-basis: 45%;
          }
          &:last-of-type{
            margin-left: 15px;
            width: 55%;
            flex-basis: 55%;
          }
        }

        p{
          text-align: left;
          margin: 0;
        }

        h2{
          text-align: left;
          font-size: 24px;
          line-height: 24px;
          margin: 0 0 20px 0;
          font-family: $font-family-trailhead;
        }

        .card-row{
          margin: 30px 0;
          justify-content: left;

          .card-link{
            width: 50%;
            flex-basis: 50%;
          }
        }

        .block-section-column--image{

          @include tabletUp(){
            margin: 40px 0;
          }

          img{
            width: 100%;
            height: auto;
            display: none;
            opacity: 0;
            transition: all 0.5s ease 0s;
          }

          .active-img{
            display: block;
            opacity: 1;
          }
        }
      }
    }

    .bodyText{
      font-size: 18px;
      line-height: 23px;
      margin: 60px auto;
    }

    hr{
      border-top: 1px solid $hr-color;
    }

  }

  .bodyimg{
    height: 25vh;
    width: auto;
    margin: 20px auto 40px auto;

    @include tabletUp() {
      height: 260px;
      width: auto;
      margin: 50px 0 70px 0;
    }
  }

  .button{
    //width: 300px;
    margin: 0 auto;
  }

  .progress-menu{
    display: flex;
    flex-direction: row;
    justify-content: center;

    div{
      margin: 0 30px;

      &:before{
        background: $white;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        content: '';
        display: inline-block;
      }

      &.active {
        color: $black;

        &:before{
          background: $black;
        }
      }
    }
  }
}

.subblock{
  @extend .animated;
  @extend .fadeIn;
  margin: 5vw;
  width: calc(100vw - 10vw);
}



.feature-block{
  @extend .animated;
  @extend .fadeIn;
  margin: 60px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid $dark_gray;

  .feature-block-heading{
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $dark_gray;

    div{
      font-weight: bold;
      font-size: 16px;
      padding: 10px 20px;
    }

    .feature-block-heading--feature{
      flex-basis: 75%;
      max-width: 75%;
    }

    .feature-block-heading--option{
      flex-basis: 25%;
      max-width: 25%;
    }
  }
  .feature-block-row{
    display: flex;
    flex-direction: row;

    div{
      font-size: 14px;
      padding: 10px 20px;
    }

    .feature-block-row--feature{
      flex-basis: 75%;
      max-width: 75%;
    }

    .feature-block-row--option{
      flex-basis: 25%;
      max-width: 25%;
    }
  }
}


.active-helper{
  opacity: 1 !important;
  display: block !important;
}
.js-toggle-option{
  display: none;
  opacity: 0;
}

.isIE{
  .block-section--sync, &.block-section--email {
    height: 380px;
  }
}

.isIE{
  .block-section--email {
    max-height: 420px;
  }
}

.block-extra{
  margin-top: 100px;

  @include tabletUp() {
    margin-top: 140px;
  }
}
