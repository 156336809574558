@mixin placeholder($color, $size:"") {
  &::-webkit-input-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &:-moz-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &::-moz-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &:-ms-input-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
}


@mixin keyframes($name) {
  @-webkit-keyframes #{$name} { @content; }
  @-moz-keyframes #{$name} { @content; }
  @-ms-keyframes #{$name} { @content; }
  @keyframes #{$name} { @content; }
}

// Mixin for animations
@mixin animation($prop) {
  -webkit-animation: $prop;
  -moz-animation: $prop;
  -o-animation: $prop;
  animation: $prop;
}

// Create animation called 'check'
@include keyframes(check) {
  0% { height: 0; width: 0; }
  25% { height: 0; width: 10px; }
  50% { height: 20px; width: 10px; }
}


@mixin mobile {
  @media (max-width: #{$width-tablet - 1px}) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: #{$width-tablet}) and (max-width: #{$width-desktop + 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$width-desktop}) {
    @content;
  }
}

@mixin tabletUp {
  @media (min-width: #{$width-tablet}) {
    @content;
  }
}


@mixin containerwidth {
  width: 95%;
  @include tablet {
    width: $width-tablet;
  }
  @include desktop {
    width: $width-desktop;
  }
}

@mixin menuheightmargintransparent {
  margin: 40px 0 0 0;
  @include desktop {
    margin: $menu-height-transparent-header 0 0 0;
  }
}

@mixin menuheightmargin {
  margin: 80px 0 0 0;
  @include desktop {
    margin: $menu-height 0 0 0;
  }
}


@mixin rotate($deg: 90){
  $sDeg: #{$deg}deg;

  -webkit-transform: rotate($sDeg);
  -moz-transform: rotate($sDeg);
  -ms-transform: rotate($sDeg);
  -o-transform: rotate($sDeg);
  transform: rotate($sDeg);
}
